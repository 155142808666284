import React from "react"

export default function Footer({ children }) {
  const year = new Date().getFullYear();
  return (
    <div className="site-footer">
      Web site ©{year} Alan Young. Photography by Uffe Schulze.
    </div>
  )
}
