import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Footer from "./footer"


const NavLink = props => (
  <li className="nav-link">
    <Link to={props.to} activeClassName="active-link">{props.children}</Link>
  </li>
)

export default function Layout(props) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div>
      <header className="site-navigation">
        <div className="site-title">
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </div>
        <nav className="site-menu">
          <ul className="nav-links">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/publications/">Publications</NavLink>
            <NavLink to="/cv/">CV</NavLink>
            <NavLink to="/contact/">Contact</NavLink>
          </ul>
        </nav>
      </header>
      <div className="layout-grid">
        <nav className="layout-grid--crumbs">
          <Breadcrumb location={props.location} crumbLabel={props.title} />
        </nav>
        <main className="layout-grid--main">
          {props.children}
        </main>
        <div className="layout-grid--footer">
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}
