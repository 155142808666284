import React from "react"
import Layout from "../components/layout"

export default function NotFound({ location }) {
  return (
    <Layout location={location} title="Page not found">
      <h1>Page not found</h1>
      <p>The page you requested was not found</p>
    </Layout>
  )
}
